export {
  isRangeError,
  isIndexOutOfRange,
  isPositionOutsideOfFragment,
  isEditorRangeError,
} from './rangeErrorHandler';

export const isFailedToFetchError = (error: Error) => {
  return error instanceof TypeError && error.message === 'Failed to fetch';
};
