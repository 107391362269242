import {
  isApiError,
  ApiError,
  type Fetcher,
  type ClientConfig,
} from '@cdm/@shared-server-notebook/endpoints/client';
import { type ApiRouteConfig } from '@cdm/@shared-server-notebook/endpoints/common/api';
import ky, { HTTPError } from 'ky';

export const fetcher: Fetcher = async (
  clientConfig: ClientConfig,
  routeConfig: ApiRouteConfig,
  path: string,
  args: any[],
) => {
  let url = `${clientConfig.baseUrl}${path}`;
  const context = clientConfig.getContext();
  url += `?ws=${context.wsId}`;
  const token = await clientConfig.getToken();

  try {
    const res = await ky(url, {
      method: 'post',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: false,
      retry: routeConfig?.unRetryable
        ? 0
        : {
            methods: ['post'],
            limit: 3,
            statusCodes: [502, 503, 504],
            delay: cnt => 0.5 * 3 ** (cnt - 1) * 1000,
          },
      json: { d: args },
    }).json();
    return res;
  } catch (err: any) {
    if (err instanceof HTTPError) {
      const _err = await getError(err.response);
      if (isApiError(_err)) {
        clientConfig.onError(_err);
      }
      throw _err;
    }
    throw err;
  }
};

export const getError = async (res: Response): Promise<any> => {
  const errObj: any = await (async function () {
    const txt = await res.text();
    try {
      return JSON.parse(txt);
    } catch (err: any) {
      return { message: txt };
    }
  })();

  if (Math.floor(res.status / 100) === 4) {
    if (res.status === 403) {
      return new ApiError(errObj.message, res.status, res.statusText, errObj.reqId);
    }
    return errObj;
  } else {
    return new ApiError(errObj.message, res.status, res.statusText, errObj.reqId);
  }
};

export const fetcherForFormData = async (
  config: ClientConfig,
  path: string,
  formData: FormData,
) => {
  let url = `${config.baseUrl}${path}`;
  const context = config.getContext();
  url += `?ws=${context.wsId}`;

  const token = await config.getToken();

  const res = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (res.ok) {
    const txt = await res.text();
    try {
      return JSON.parse(txt);
    } catch (err: any) {
      return txt;
    }
  } else {
    const err = await getError(res);
    if (isApiError(err)) {
      config.onError(err);
    }
    throw err;
  }
};
