import {
  type ApiRouteConfig,
  type ApiRouteConfigs,
  type ApiRoutes,
  getApiEntries,
} from '../common/api';

export class ApiError extends Error {
  name = 'ApiError';
  status: number;
  statusText: string;
  reqId: string;

  constructor(message: string, status: number, statusText: string, reqId: string) {
    super(message);
    this.status = status;
    this.reqId = reqId;
    this.statusText = statusText;
  }
}

export const isApiError = (err: any): err is ApiError => {
  return err instanceof ApiError;
};

export type ClientConfig = {
  baseUrl: string;
  getContext: () => { wsId: string };
  getToken: () => Promise<string>;
  onError: (error: ApiError) => void;
};
export type Fetcher = (
  clientConfig: ClientConfig,
  routeConfig: ApiRouteConfig,
  path: string,
  args: any[],
) => Promise<any>;

export function getApiClient<TApi>(
  routes: ApiRoutes<TApi>,
  routeConfigs: ApiRouteConfigs<TApi>,
  fetcher: Fetcher,
  config: ClientConfig,
): TApi {
  const api = {} as any;
  for (const { name, path } of getApiEntries(routes)) {
    api[name] = async (...args: any[]) => {
      return await fetcher(config, routeConfigs[name], path, args);
    };
  }
  return api;
}

/* getApiClient の呼び出し時に wsId を固定にする版 */
export function getApiClientByWsId<TApi>(
  routes: ApiRoutes<TApi>,
  routeConfigs: ApiRouteConfigs<TApi>,
  fetcher: Fetcher,
  config: Omit<ClientConfig, 'getContext'> & { wsId: string },
): TApi {
  return getApiClient(routes, routeConfigs, fetcher, {
    baseUrl: config.baseUrl,
    getContext: () => ({ wsId: config.wsId }),
    getToken: config.getToken,
    onError: config.onError,
  });
}
