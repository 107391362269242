import type { App } from 'vue';
import type { Router } from 'vue-router';

import * as Sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import { ApiError as NotebookApiError } from '@cdm/@shared-server-notebook/endpoints/client';
import { ApiError as GeneralApiError } from '../../clients/fetcher';
import {
  isRangeError,
  isIndexOutOfRange,
  isPositionOutsideOfFragment,
  isFailedToFetchError,
} from '../../libs/error';
import { isUserError } from '../../errors/UserError';
import { GlobalConfig } from '@cdm/configs';

const sentryEnabled = GlobalConfig.SENTRY_DSN !== '';

// MEMO: パフォーマンスへの影響を見るために disable できるようにする（開発者向け）
const getSentryDisabled = () => localStorage.getItem('sentryDisabled') === 'true';

const modifyEventTitle = <E extends Sentry.Event>(event: E) => {
  if (!event.exception?.values || event.exception.values?.length === 0) {
    return event;
  }
  const exception = event.exception.values.map(e => {
    const newType = e.value;
    const newValue = e.type;
    e.type = newType;
    e.value = newValue;
    return e;
  });
  event.exception.values = exception;
  return event;
};

export function initLogger(app: App, router: Router) {
  if (!sentryEnabled) return;
  if (getSentryDisabled()) {
    console.warn('Sentry is disabled by localStorage flag.');
    return;
  }

  const release =
    GlobalConfig.ENVIRONMENT === 'production' || GlobalConfig.ENVIRONMENT === 'evaluation'
      ? window.CODATUM_RELEASE_VERSION
      : undefined;

  Sentry.init({
    app,
    dsn: GlobalConfig.SENTRY_DSN,
    integrations: [
      Sentry.browserApiErrorsIntegration({
        setTimeout: false,
        setInterval: false,
        requestAnimationFrame: false,
        XMLHttpRequest: false,
        eventTarget: false,
      }),
      Sentry.browserTracingIntegration({ router }),
      // Sentry.captureConsoleIntegration({ handled: false }),
      // unhandledrejectionは自前でhandling
      Sentry.globalHandlersIntegration({ onerror: true, onunhandledrejection: false }),
      Sentry.vueIntegration({
        tracingOptions: {
          // https://github.com/codatum/cdm/issues/2454
          // MEMO: 重いので一旦無効化（入れるなら対象コンポーネントを絞ったほうが良さそう）
          trackComponents: false,
        },
      }),
    ],
    attachStacktrace: true,

    tracePropagationTargets: ['localhost', 'api.cdm.test', 'api.evacdm.com', 'api.codatum.com'],
    // replaysSessionSampleRate: 0.0,
    // replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    environment: GlobalConfig.ENVIRONMENT,
    release: release,
    beforeBreadcrumb: (breadcrumb, _hint) => {
      if (breadcrumb.category === 'console') {
        const [arg] = breadcrumb.data?.arguments || [];
        if (isRangeError(arg)) {
          // NotebookのContentが含まれる可能性があるので除外する
          if (isPositionOutsideOfFragment(arg) || isIndexOutOfRange(arg)) {
            return null;
          }
        }
      }
      return breadcrumb;
    },
    beforeSend: (event, _hint) => {
      return modifyEventTitle(event);
    },
    beforeSendTransaction: (event, _hint) => {
      return modifyEventTitle(event);
    },
  });
  Sentry.getGlobalScope().setTag('releaseVersion', release);

  // app.config.errorHandler で捕捉できないエラーを収集
  window.addEventListener('error', err => {
    if (err.error instanceof Error) {
      logError(err.error);
    } else {
      console.error(err);
    }
  });

  window.addEventListener('unhandledrejection', event => {
    if (event.reason instanceof Error) {
      logError(event.reason);
    } else {
      console.error('unhandledrejection', event);
    }
  });
}

export function setUserInContext(userId: string) {
  if (!sentryEnabled) return;
  Sentry.setUser({ id: userId });
  datadogRum.setUser({ id: userId });
}

export function setTagInContext(key: string, value: string) {
  if (!sentryEnabled) return;
  Sentry.setTag(key, value);
  datadogRum.addRumGlobalContext(key, value);
}

export function setExtraInContext(key: string, value: string) {
  if (!sentryEnabled) return;
  Sentry.setExtra(key, value);
}

type SentryTag = {
  errorId?: string;
  reqId?: string;
};

/** sentry にエラーを送信する用の関数 */
export function logError(error: Error, level?: Sentry.SeverityLevel, extra?: Record<string, any>) {
  if (!sentryEnabled) return;

  const errorId = crypto.randomUUID ? crypto.randomUUID() : `err-${Date.now()}-${Math.random()}`;
  console.error(`Error ID: ${errorId}`);
  console.error(error);
  const tags: SentryTag = {
    errorId,
  };
  if ('reqId' in error && typeof error.reqId === 'string') tags.reqId = error.reqId;

  const defaultLevel = (error: Error) => {
    if (error.message.match(/Async component timed out after \d+ms\./)) {
      return 'warning';
    }
    if (isUserError(error) || isFailedToFetchError(error)) {
      return 'warning';
    }
    return 'error';
  };
  const _level = level || defaultLevel(error);

  Sentry.captureException(error, { tags, level: _level, extra: extra || {} });
}

// TODO: 共通化したい
type ApiError = GeneralApiError | NotebookApiError;

export function logApiError(
  error: ApiError,
  level?: Sentry.SeverityLevel,
  extra?: Record<string, any>,
) {
  if (!sentryEnabled) return;

  // 500はサーバー側で適切に処理されていない例外も含まれるため、ノイズとなる可能性がある。
  if (error.status > 500 && error.status < 600) {
    logError(error, level, extra);
  }
}

export const DEBUG_LOG_NAMES = {
  GRID_STORE: 'GRID_STORE',
  GRID_JOB: 'GRID_JOB',
  NOTEBOOK_EDITOR: 'NOTEBOOK_EDITOR',
  S2_TABLE: 'S2_TABLE',
} as const;

type DebugLogName = typeof DEBUG_LOG_NAMES[keyof typeof DEBUG_LOG_NAMES];

// MEMO: 開発時に出力したいログをここで設定する
const ENABLED_DEBUG_LOGS: { [key in DebugLogName]: boolean } = {
  GRID_STORE: false,
  GRID_JOB: false,
  NOTEBOOK_EDITOR: false,
  S2_TABLE: false,
};

export const getDebugLogger =
  (logName: DebugLogName, options: { enableOnProd?: boolean; force?: boolean } = {}) =>
  (...args: any[]) => {
    if (!options.enableOnProd && import.meta.env.PROD) return;
    if (!ENABLED_DEBUG_LOGS[logName] && !options.force) return;
    console.debug(`[${logName}]`, ...args);
  };
