export class UserError extends Error {
  name = 'UserError';
  isUserError: boolean = true;

  constructor(message: string) {
    super(message);
  }
}

export const isUserError = (error: Error): error is UserError => {
  return error instanceof UserError;
};
