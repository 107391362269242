<script setup lang="ts">
import { computed } from 'vue';
import { ApiError as NodeApiError } from '@cdm/@shared-server-notebook/endpoints/client';
import { ApiError as GoApiError } from '@cdm/clients/fetcher';
import { logError } from '@cdm/utils/logger';

const props = defineProps<{
  error: GoApiError | NodeApiError | Error;
}>();

const isUserError = computed(() => {
  return !!('isUserError' in props.error && props.error.isUserError);
});
const isClientError = computed(() => {
  const err = props.error;
  if (!err) return false;
  return 'status' in err && err.status >= 400 && err.status < 500;
});

if (props.error) {
  if (isUserError.value || isClientError.value) {
    logError(props.error, 'warning');
  } else {
    logError(props.error);
  }
}

const statusCode = computed(() => {
  if (props.error instanceof GoApiError || props.error instanceof NodeApiError) {
    return props.error.status;
  }
  return 500;
});
</script>

<template>
  <div class="ApiError">
    <a-empty>
      <template #image>
        <fa i="face-dizzy" far style="margin: 0 0 12px; font-size: 32px" />
      </template>
      <strong>{{ statusCode }} error</strong>
      <template v-if="isUserError">
        <span class="ErrorMessage">
          {{ props.error.message }}
        </span>
      </template>
      <template v-else-if="statusCode === 403">
        <span class="ErrorMessage">
          {{ props.error.message }}
        </span>
      </template>
      <template v-else-if="statusCode === 404">
        <span class="ErrorMessage">
          {{ props.error.message }}
        </span>
      </template>
      <template v-else-if="400 <= statusCode && statusCode < 500">
        <span class="ErrorMessage">
          {{ props.error.message }}
        </span>
      </template>
      <template v-else>
        <span> Unknown error </span>
      </template>
      <slot />
    </a-empty>
  </div>
</template>

<style scoped>
.ApiError {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
  max-width: 400px;
  margin: 0 auto;
}

.ErrorMessage {
  overflow-wrap: break-word;
}
</style>
