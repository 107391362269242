import { ApiError, getApiClient } from '../@shared-server-notebook/endpoints/client';
import {
  workspaceSettingRouteConfigs,
  workspaceSettingRoutes,
} from '../@shared-server-notebook/endpoints/implements/workspaceSetting';
import { logApiError } from '../utils/logger';
import { fetcher } from './fetcherForNotebookServer';
import { GlobalConfig } from '@cdm/configs';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';
import { getToken } from '@cdm/libs/auth';

export const workspaceApiClient = getApiClient(
  workspaceSettingRoutes,
  workspaceSettingRouteConfigs,
  fetcher,
  {
    baseUrl: `${GlobalConfig.NOTEBOOK_URL}/api`,
    getContext: () => AccountStore.getApiContext(),
    getToken: () => getToken(AccountStore.getWorkspaceId()),
    onError: (error: ApiError) => {
      logApiError(error);
    },
  },
);
