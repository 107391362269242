export type ApiRoutes<TApi> = Record<keyof TApi, string>;

export type ApiRouteConfig = {
  unRetryable?: boolean;
};
export type ApiRouteConfigs<TApi> = Record<keyof TApi, ApiRouteConfig>;

export type ApiEntry<TApi> = {
  name: keyof TApi;
  path: string;
};

export function getApiEntries<TApi>(routes: ApiRoutes<TApi>): ApiEntry<TApi>[] {
  return Object.entries(routes).map(([key, value]: any) => ({
    name: key,
    path: value,
  }));
}

// express の handler
type RequestHandler = (req: any, res: any, next: any) => void;
type ErrorRequestHandler = (err: any, req: any, res: any, next: any) => void;

export type Api<TApi> = {
  routes: ApiRoutes<TApi>;
  actions: {
    [K in keyof TApi]: (...args: any[]) => Promise<any>;
  };
  middlewares: {
    [K in keyof TApi]: RequestHandler[];
  };
  afterwares?: {
    [K in keyof TApi]?: (RequestHandler | ErrorRequestHandler)[];
  };
  requireAdmins?: {
    [K in keyof TApi]?: boolean;
  };
};
