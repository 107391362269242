export const isRangeError = (err: Error): err is RangeError => {
  return err instanceof RangeError;
};

export const isPositionOutsideOfFragment = (error: Error) => {
  return isRangeError(error) && /^Position \d+ outside of fragment \(.*\)/.test(error.message);
};

export const isIndexOutOfRange = (error: Error) => {
  return isRangeError(error) && /^Index \d+ out of range for .*/.test(error.message);
};

export const isPositionOutOfRange = (error: Error) => {
  return isRangeError(error) && /^Position \d+ out of range/.test(error.message);
};

export const isEditorRangeError = (error: Error) => {
  return (
    isPositionOutsideOfFragment(error) || isIndexOutOfRange(error) || isPositionOutOfRange(error)
  );
};
