import { dynamicImportWithRetry } from '@fatso83/retry-dynamic-import';

requestAnimationFrame(async () => {
  // パフォーマンス向上のため非同期で読み込む
  try {
    const [lottie, element] = await Promise.all([
      dynamicImportWithRetry(() => import('lottie-web')),
      dynamicImportWithRetry(() => import('@lordicon/element')),
    ]);
    element.defineElement(lottie.default.loadAnimation);
  } catch (error) {
    const err = error as Error;
    console.error(`Failed to load lordicon: ${err.message}`);
  }
});
