import { ApiClient } from '@cdm/@generated-account-client/auth-client';
import { logApiError } from '../utils/logger';
import { ApiError, createFetcher } from './fetcher';

export const accountAuthClient = new ApiClient(
  createFetcher({
    onError: (error: ApiError) => {
      logApiError(error);
    },
  }),
);
