import { ApiClient } from '../@generated-account-client/client';
import { logApiError } from '../utils/logger';
import { ApiError, createFetcher } from './fetcher';

import { getToken } from '@cdm/libs/auth';

export const accountClient = new ApiClient(
  createFetcher({
    getToken,
    onError: (error: ApiError) => {
      logApiError(error);
    },
  }),
);
